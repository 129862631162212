/* eslint-disable import/no-extraneous-dependencies */
import { Component, Input, OnInit } from "@angular/core";
import { ITransmittalDocumentDownload, ITransmittalListItem } from "@api";
import TransmittalRole from "@models/TransmittalRole";
import TransmittalStatus from "@models/TransmittalStatus";
import LocalContractUserService from "@services/local-contractUser.service";

@Component({
  selector: "leftPanel",
  templateUrl: "./leftPanel.component.html",
})
export default class LeftPanelComponent implements OnInit {
  @Input() entity: ITransmittalListItem;

  @Input() docs: ITransmittalDocumentDownload[];

  defaultTab = "details";

  isDBAdmin = true;

  constructor(public localContractUserService: LocalContractUserService){}
  
  ngOnInit(): void {
    const role = this.localContractUserService.currentUserContractRole;
    this.isDBAdmin = role === TransmittalRole.DBAdmin;
    console.log(`${this.isDBAdmin}`);
    this.defaultTab = (role === TransmittalRole.DocControl && this.entity.Status !== TransmittalStatus.Draft) ? "processing" : "details";
  }
}
